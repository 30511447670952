<template>
  <div class="container">
    <!--    <BackButton />-->
    <TitleHeader>{{ getApplicationFullName }} Pension Report Review</TitleHeader>
    <div class="card">
      <div class="card-body container text-left">
        <div class="row no-gutters p-2">
          <div class="col-12">
            <h2>Pension Details</h2>
            <div v-if="getTracedPension?.current_provider?.name" class="col px-4">
              <div class="row no-gutters w-100 mb-3 align-content-baseline justify-content-start">
                <div class="font-weight-bold">Provider Name:&nbsp;&nbsp;</div>
                <div class="text-dark ">{{getTracedPension?.current_provider?.name}}</div>
              </div>
              <div v-if="getStoredAggregationReport?.policy_number?.value" class="row no-gutters w-100 mb-3 align-content-baseline justify-content-start">
                <div class="font-weight-bold">Policy Number:&nbsp;&nbsp;</div>
                <div class="text-dark ">{{getStoredAggregationReport?.policy_number?.value}}</div>
              </div>
              <div v-if="getTracedPension?.current_provider?.email" class="row no-gutters w-100 mb-3 align-content-baseline justify-content-start">
                <div class="font-weight-bold">Provider Email:&nbsp;&nbsp;</div>
                <div class="text-dark ">{{getTracedPension?.current_provider?.email}}</div>
              </div>
              <div v-if="getTracedPension?.pension_provider[0]?.current_phone_number_formatted" class="row no-gutters w-100 mb-3 align-content-baseline justify-content-start">
                <div class="font-weight-bold">Provider Telephone Number:&nbsp;&nbsp;</div>
                <div class="text-dark ">{{getTracedPension?.pension_provider[0]?.current_phone_number_formatted}}</div>
              </div>
              <div v-if="getTracedPension?.employment[0]?.name_of_employer" class="row no-gutters w-100 mb-3 align-content-baseline justify-content-start">
                <div class="font-weight-bold">Employer:&nbsp;&nbsp;</div>
                <div class="text-dark ">{{getTracedPension?.employment[0]?.name_of_employer}}</div>
              </div>
            </div>
<!--            <div>-->
<!--              <h2>Known values</h2>-->
<!--            </div>-->
<!--            <PensionReportCol class="text-success" :values="knownValuesArr" />-->
            <div>
              <h2>Unknown/Uncertain values</h2>
            </div>
            <PensionReportCol class="text-danger" :values="unknownValuesArr" />
          </div>
        </div>

        <button class="fabric-btn fabric-btn-submit" @click="handleGenerate">
          Generate Report
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import { useDateFormat } from "@/logic/useDateFormat";
import TitleHeader from "../../components/layout/TitleHeader";
import PensionReportCol from "../../components/layout/PensionReportCol";

export default {
  name: "Aggregation Form",
  data: () => ({
  }),
  components: {
    TitleHeader,
    PensionReportCol,
  },
  setup() {
    const { formatDate } = useDateFormat();
    return {
      formatDate
    };
  },
  async mounted() {
    this.setLoading({ bool: true });

    // let auuid = this.$route.params.auuid;
    let tpuuid = this.$route.params.tpuuid;

    await this.clearMLData();

    await this.getTracedPensionFocus(tpuuid).catch(err =>
      console.log(err)
    );

    // check if trace has translate ID then perform get

    // let mlResponse = await this.getMLData({ auuid, tpuuid }).catch(err => {
    //   this.setNotification({
    //     show: true,
    //     type: "error",
    //     message: "Fabric Translate Data Error!",
    //     content: this.formulateErrorString(err)
    //   });
    // });

    let aggResponse = await this.getAggregationReport(tpuuid).catch(err =>
      console.error(err.response.data.error)
    );

    await Promise.all([aggResponse]);

    this.setLoading({ bool: false });
  },
  computed: {
    ...mapGetters([
      "getApplicationUuid",
      "getApplicationFullName",
      "getApplication",
      "getTracedPension",
      "getStoredAggregationReport"
    ]),
    formatName() {
      return `${this.getApplication?.first_name} ${this.getApplication?.last_name}`;
    },
    pensionValueArr() {
      return [
      {
        title: "Policy number:",
        value: this.getStoredAggregationReport?.policy_number?.value
      },
      {
        title: "Pension start date:",
        value: this.reportDateComputed
      },
      {
        title: "Selected retirement date:",
        value: this.retirementDateComputed
      },
      {
        title: "Registered scheme name:",
        value: this.getStoredAggregationReport?.registered_scheme_name?.value
      },
      {
        title: "Pension scheme tax reference:",
        value: this.getStoredAggregationReport?.pension_scheme_tax_reference?.value
      },
      {
        title: "Pension name:",
        value: this.getStoredAggregationReport?.pension_name?.value
      },
      {
        title: "Pension value:",
        value: (this.getStoredAggregationReport?.fund_value?.value)
          ? `£${this.formatCurrency(this.getStoredAggregationReport?.fund_value?.value)}`
          : null
      },
      {
        title: "Pension value date:",
        value: this.fundValueDateComputed
      },
      {
        title: "Transfer value:",
        value: (this.getStoredAggregationReport?.transfer_value?.value)
          ? `£${this.formatCurrency(this.getStoredAggregationReport?.transfer_value?.value)}`
          : null
      },
      {
        title: "Transfer value date:",
        value: this.transferValueDateComputed
      },
      {
        title: "Policy permits full transfers out:",
        value: this.getStoredAggregationReport?.policy_permits_full_transfer_out?.value
      },
      {
        title: "Contribution currently being made:",
        value: this.getStoredAggregationReport?.contributions_currently_being_made?.value
      },
      {
        title: "Employer currently contributing:",
        value: this.getStoredAggregationReport?.employer_contribution?.value
      },
      {
        title: "Accepts singular contributions:",
        value: this.getStoredAggregationReport?.singular_contributions?.value
      },
      {
        title: "Regular contributions can be restarted:",
        value: this.getStoredAggregationReport?.regular_contributions?.value
      },
      {
        title: "Crystallised pension:",
        value: this.getStoredAggregationReport?.crystallised_pension?.value
      },
      {
        title: "Has made withdrawls from this pension:",
        value: this.getStoredAggregationReport?.withdrawels_from_this_pension?.value
      },
      {
        title: "Invested in a with-profits fund:",
        value: this.getStoredAggregationReport?.with_profits_fund?.value
      },
      {
        title: "Safeguarded benefits:",
        value: this.getStoredAggregationReport?.safeguard_benefits?.value
      },
      {
        title: "Accepts transfers in:",
        value: this.getStoredAggregationReport?.policy_accept_transfers_in?.value
      },
      {
        title: "Annual management charge:",
        value: this.getStoredAggregationReport?.annual_management_fee?.value
      },
      {
        title: "Fund management charge:",
        value: this.getStoredAggregationReport?.fund_management_fee?.value
      },
      {
        title: "Fund switching fees:",
        value: this.getStoredAggregationReport?.fund_switching_fees?.value
      },
      {
        title: "Advisor fees:",
        value: (this.getStoredAggregationReport?.advisor_fees?.value)
          ? this.parseAmount(this.getStoredAggregationReport?.advisor_fees?.value)
          : null
      },
      {
        title: "Exit charges and penalties:",
        value: this.getStoredAggregationReport?.fees_charges_penalties?.value
      },
      {
        title: "Text for exit charges and penalties:",
        value: this.getStoredAggregationReport
          ?.fees_charges_penalties_text?.value
      },
      {
        title: "Guaranteed income (annuity):",
        value: this.getStoredAggregationReport?.guaranteed_income?.value
      },
      {
        title: "Income drawdown:",
        value: this.getStoredAggregationReport?.adjustable_income?.value
      },
      {
        title: "Take pension in cash:",
        value: this.getStoredAggregationReport?.take_cash_in_chunks?.value
      },
      {
        title: "Waiver of Contribution:",
        value: this.getStoredAggregationReport?.waiver_of_premium?.value
      },
      {
        title: "Text for waiver of contribution:",
        value: this.getStoredAggregationReport?.waiver_of_premium_text?.value
      },
      {
        title: "Provides death benefits:",
        value: this.getStoredAggregationReport?.death_benefits?.value
      },
      {
        title: "Text for death benefits:",
        value: this.getStoredAggregationReport?.death_benefits_text?.value
      },
      {
        title: "Has life assurance:",
        value: this.getStoredAggregationReport?.life_assurance?.value
      },
      {
        title: "Text for life assurance:",
        value: this.getStoredAggregationReport?.life_assurance_text?.value
      },
      {
        title: "Protect retirement age:",
        value: this.getStoredAggregationReport?.protect_retirement_age?.value
      },
      {
        title: "Text for protected retirement age:",
        value: this.getStoredAggregationReport
          ?.protect_retirement_age_text?.value
      },
      {
        title: "Tax-free lump sum greater than 25%:",
        value: this.getStoredAggregationReport
          ?.tax_free_cash_greater_than_25_perc?.value
      },
      {
        title: "Loyalty bonuses:",
        value: this.getStoredAggregationReport?.loyalty_bonus?.value
      },
      {
        title: "Text for loyalty bonuses:",
        value: this.getStoredAggregationReport?.loyalty_bonus_text?.value
      },
    ]
  },
    knownValuesArr() {
      return this.pensionValueArr.filter(({ title, value, }) => value && value !== "No date set");
    },

    unknownValuesArr() {
      return this.pensionValueArr.filter(({ title, value, }) => value === "No date set" || !value);
    },

    employerComputed() {
      if (!this.getApplication.employment) return;
      return this.getApplication?.employment[0]?.name_of_employer;
    },
    dobComputed() {
      return this.formatDate(this.getApplication?.dob);
    },
    fundValueDateComputed() {
      return this.formatDate(
        this.getStoredAggregationReport?.fund_value_date?.value
      );
    },
    reportDateComputed() {
      return this.formatDate(
        this.getStoredAggregationReport?.policy_commence_date?.value
        
      );
    },
    policyCommenceDateComputed() {
      return this.formatDate(
        this.getStoredAggregationReport?.policy_commence_date?.value
      );
    },
    retirementDateComputed() {
      return this.formatDate(
        this.getStoredAggregationReport?.retirement_date?.value
      );
    },
    transferValueDateComputed() {
      return this.formatDate(
        this.getStoredAggregationReport?.transfer_value_date?.value
      );
    },
  },
  methods: {
    ...mapMutations(["setLoading", "setNotification"]),
    ...mapActions([
      "generateAggregationReport",
      "getApplicationsFocus",
      "getTracedPensionFocus",
      'clearMLData',
      'getMLData',
      'getAggregationReport'
    ]),
    async handleGenerate() {
      this.setLoading({
        bool: true
      });
      let response = await this.generateAggregationReport({
        auuid: this.getStoredAggregationReport.uuid
      }).catch(err => console.log(err));
      this.setLoading({
        bool: false
      });
      if (response.status == 200) {
        this.setNotification({
          show: true,
          type: "success",
          message: "Report is being generated..."
        });

        this.$router.push({
          name: "Traced Pension",
          params: {
            type: "aggregation-edit",
            uuid: this.getApplicationUuid,
            tpuuid: this.getTracedPension.uuid
          }
        });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.confirmationYes {
  display: block;
  width: 300px;
}
</style>
