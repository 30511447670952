<template>
  <div class="col px-4">
    <div
        v-for="(pensionValue, index) in values"
        :key="index"
        class="row no-gutters w-100 mb-3 align-content-baseline justify-content-between"
        :class="{ 'border-bottom border-secondary': true }"
    >
      <div class="font-weight-bold">{{ pensionValue.title }}</div>
      <div class="text-dark ">{{ pensionValue.value }}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PensionReportCol",
  props: {
    values: {
      type: Array,
      default: null
    },
  }
};
</script>
<style lang="scss" scoped></style>
